import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { Col, FormControl, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

interface SearchBarProps {
    setSearchValue: Dispatch<SetStateAction<string>>
}

const SearchBar = ({ setSearchValue }: SearchBarProps) => (
    <Col className='col-12 col-sm-5 col-md-6 col-lg-7 col-xl-8 col-xxl-9'>
        <InputGroup>
            <InputGroup.Text>
                <i>
                    <FontAwesomeIcon icon={faSearch} />
                </i>
            </InputGroup.Text>
            <FormControl
                type='text'
                placeholder='Suche nach Raumname'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setSearchValue(event.target.value)
                }}
            />
        </InputGroup>
    </Col>
)

export default SearchBar
