import {
    createApi,
    fetchBaseQuery,
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    retry,
} from '@reduxjs/toolkit/query/react'

import {
    LoginRequest,
    ChangePasswordRequest,
    RequestPasswordChangeRequest,
    ConfirmPasswordChangeRequest,
    RootState,
    RequestEmailChangeRequest,
    SetPasswordRequest,
    ConfirmEmailChangeRequest,
    GetUserRequest,
    LoginResponse,
    SetPasswordResponse,
    UpdateTicketStatusRequest,
    UpdateTicketPlannedTimestampRequest,
} from './types'
import { Provider, Room, Ticket, Token, User } from 'common/types'
import {
    cleanAuthState,
    setToken,
    selectCurrentRefreshToken,
} from 'features/auth/authSlice'
import { BaseQueryError } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
        const idToken = (getState() as RootState).auth.token?.id
        if (idToken) {
            headers.set('authorization', `Bearer ${idToken}`)
        }

        return headers
    },
})

const baseQueryReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    // refresh token when refresh token exists
    if (result?.error?.status === 403) {
        const refreshToken = selectCurrentRefreshToken(
            api.getState() as RootState
        )

        if (refreshToken) {
            const refreshResult = await baseQuery(
                {
                    method: 'POST',
                    url: '/auth/refresh-access-token',
                    body: { refreshToken: refreshToken },
                },
                api,
                extraOptions
            )

            // store resulted token
            if (
                refreshResult &&
                refreshResult.data &&
                //@ts-ignore
                refreshResult.data.token &&
                //@ts-ignore
                refreshResult.data.token.access &&
                //@ts-ignore
                refreshResult.data.token.id
            ) {
                const token: Token = {
                    refresh: refreshToken,
                    //@ts-ignore
                    access: refreshResult.data.token.access,
                    //@ts-ignore
                    id: refreshResult.data.token.id,
                }

                api.dispatch(setToken(token))
                result = await baseQuery(args, api, extraOptions)
            } else {
                api.dispatch(cleanAuthState())
            }
        } else {
            api.dispatch(cleanAuthState())
        }
    }
    if (result?.error?.status === 401) {
        api.dispatch(cleanAuthState())
    }
    return result
}

const baseQueryReauthRetry = retry(baseQueryReauth, { maxRetries: 2 })

const api = createApi({
    baseQuery: baseQueryReauthRetry,
    tagTypes: ['Ticket'],
    endpoints: build => ({
        setPassword: build.mutation<SetPasswordResponse, SetPasswordRequest>({
            query: body => ({
                method: 'POST',
                url: '/auth/set-password',
                body,
            }),
        }),
        login: build.mutation<LoginResponse, LoginRequest>({
            query: body => ({
                method: 'POST',
                url: '/auth/login',
                body,
            }),
        }),
        changePassword: build.mutation<void, ChangePasswordRequest>({
            query: body => ({
                method: 'POST',
                url: '/auth/change-password',
                body,
            }),
        }),
        requestPasswordChange: build.mutation<
            void,
            RequestPasswordChangeRequest
        >({
            query: body => ({
                method: 'POST',
                url: '/auth/request-password-change',
                body,
            }),
        }),
        confirmPasswordChange: build.mutation<
            void,
            ConfirmPasswordChangeRequest
        >({
            query: body => ({
                method: 'POST',
                url: '/auth/confirm-password-change',
                body,
            }),
        }),
        requestEmailChange: build.mutation<void, RequestEmailChangeRequest>({
            query: body => ({
                method: 'PATCH',
                url: '/auth/request-email-change',
                body,
            }),
        }),
        confirmEmailChange: build.mutation<void, ConfirmEmailChangeRequest>({
            query: body => ({
                method: 'POST',
                url: '/auth/confirm-email-change',
                body,
            }),
        }),
        getUser: build.mutation<User, GetUserRequest>({
            query: body => ({
                method: 'POST',
                url: '/auth/user',
                body,
            }),
        }),
        getProvider: build.mutation<Provider, string>({
            query: providerId => ({
                method: 'GET',
                url: `/provider/${providerId}`,
            }),
        }),
        getTicketsByProviderId: build.query<Ticket[], string>({
            query: providerId => ({
                method: 'GET',
                url: `/provider/${providerId}/ticket`,
            }),
            transformErrorResponse: (response: BaseQueryError<any>) => {
                return response
            },

            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: 'Ticket' as const,
                              id,
                          })),
                          'Ticket',
                      ]
                    : ['Ticket'],
        }),
        getRoomsByProviderId: build.query<Room[], string>({
            query: providerId => ({
                method: 'GET',
                url: `/provider/${providerId}/room`,
            }),
        }),
        updateTicketStatus: build.mutation<void, UpdateTicketStatusRequest>({
            query: ({ providerId, ticketId, body }) => ({
                method: 'PATCH',
                url: `/provider/${providerId}/ticket/${ticketId}/update-status`,
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Ticket', id: arg.ticketId },
            ],
        }),
        updateTicketPlannedTimestamp: build.mutation<
            void,
            UpdateTicketPlannedTimestampRequest
        >({
            query: ({ providerId, ticketId, body }) => ({
                method: 'PATCH',
                url: `/provider/${providerId}/ticket/${ticketId}/update-planned-timestamp`,
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Ticket', id: arg.ticketId },
            ],
        }),
    }),
})

export const {
    useSetPasswordMutation,
    useLoginMutation,
    useChangePasswordMutation,
    useRequestPasswordChangeMutation,
    useConfirmPasswordChangeMutation,
    useRequestEmailChangeMutation,
    useConfirmEmailChangeMutation,
    useGetUserMutation,
    useGetProviderMutation,
    useGetTicketsByProviderIdQuery,
    useLazyGetTicketsByProviderIdQuery,
    useGetRoomsByProviderIdQuery,
    useUpdateTicketStatusMutation,
    useUpdateTicketPlannedTimestampMutation,
} = api

export default api
