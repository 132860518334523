import { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import { ButtonGroup, Dropdown, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faDownload,
    faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons'

import { EXPORT_FORMAT } from 'common/types'
import { GROUP_BY, MappedTicket } from 'features/dashboard/types'
import {
    selectCurrentIdToken,
    selectCurrentProvider,
    selectCurrentProviderId,
} from 'features/auth/authSlice'
import { useExportTickets } from 'features/dashboard/hooks'

interface GroupByMenuProps {
    tickets: MappedTicket[]
    groupByKey: GROUP_BY | null
    setGroupByKey: Dispatch<SetStateAction<GROUP_BY | null>>
}

const TicketsListMenu = ({
    tickets,
    groupByKey,
    setGroupByKey,
}: GroupByMenuProps) => {
    const { exportPdfTickets, exportRawTickets } = useExportTickets()
    const provider = useSelector(selectCurrentProvider)
    const providerId = useSelector(selectCurrentProviderId)
    const idToken = useSelector(selectCurrentIdToken)

    return (
        <ButtonGroup className='ds-btn-group ds-dropdown-toggle' as={Dropdown}>
            <Dropdown.Toggle variant='text-dark'>
                <i>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header className='ds-dropdown-headline'>
                    Alle Tickets exportieren
                </Dropdown.Header>
                {Object.values(EXPORT_FORMAT)
                    .filter(format => isNaN(Number(format)))
                    .map((value, index) => (
                        <Dropdown.Item
                            key={`export-dropdown-${index}`}
                            onClick={() => {
                                if (value !== 'PDF') {
                                    if (providerId && idToken) {
                                        exportRawTickets(
                                            providerId,
                                            idToken,
                                            tickets,
                                            EXPORT_FORMAT[value],
                                            2
                                        )
                                    }
                                } else {
                                    exportPdfTickets(provider, tickets)
                                }
                            }}
                        >
                            <i className='text-color-n3 me-3'>
                                <FontAwesomeIcon icon={faDownload} size='lg' />
                            </i>
                            Als {EXPORT_FORMAT[value]}
                        </Dropdown.Item>
                    ))}
                <Dropdown.Divider />
                <Dropdown.Header className='ds-dropdown-headline'>
                    Gruppieren
                </Dropdown.Header>
                <Form.Check
                    type='switch'
                    label='Nach Objekt'
                    value={GROUP_BY.BS_OBJECT_NAME}
                    className='m-3'
                    checked={groupByKey === GROUP_BY.BS_OBJECT_NAME}
                    onChange={() => {
                        if (groupByKey === GROUP_BY.BS_OBJECT_NAME) {
                            setGroupByKey(null)
                        } else {
                            setGroupByKey(GROUP_BY.BS_OBJECT_NAME)
                        }
                    }}
                />
                <Form.Check
                    type='switch'
                    label='Nach Wohneinheit'
                    value={GROUP_BY.BS_ROOM_NAME}
                    className='m-3'
                    checked={groupByKey === GROUP_BY.BS_ROOM_NAME}
                    onChange={() => {
                        if (groupByKey === GROUP_BY.BS_ROOM_NAME) {
                            setGroupByKey(null)
                        } else {
                            setGroupByKey(GROUP_BY.BS_ROOM_NAME)
                        }
                    }}
                />
            </Dropdown.Menu>
        </ButtonGroup>
    )
}

export default TicketsListMenu
