import { Fragment, Dispatch, SetStateAction, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import {
    Card,
    Col,
    Form,
    Row,
    Spinner,
    Stack,
    Button,
    Modal,
    FormGroup,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBroom,
    faDownload,
    faLocationDot,
    faTriangleExclamation,
    faClock,
} from '@fortawesome/free-solid-svg-icons'

import {
    UPDATE_STATUS,
    MappedTicket,
    UpdateTicketInput,
    EXECUTION_DAY,
} from './types'
import {
    TICKET_KIND_MAP,
    TICKET_STATUS_MAP,
    ResponseError,
    ResponseNotification,
    TICKET_KIND,
} from 'common/types'
import ResponseAlert from 'common/alert/ResponseAlert'
import {
    useUpdateTicketPlannedTimestampMutation,
    useUpdateTicketStatusMutation,
} from 'app/api'
import {
    cleanAuthState,
    selectCurrentProvider,
    selectCurrentProviderId,
} from 'features/auth/authSlice'
import { useExportTickets } from './hooks'
import {
    formatExecutionDay,
    isExecutionSoon,
    isSameDay,
    getMappedTicketStatus,
    isTicketInProgress,
    formatDateToLocaleTimeString,
} from './utils'

interface TicketDetailsProps {
    ticket: MappedTicket
    plannedExecutionDate: Date
    latestExecutionDate: Date
    dueAlertColor: string
    showTicketChangeAlert: boolean
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
}

const TicketDetails = ({
    ticket,
    plannedExecutionDate,
    latestExecutionDate,
    dueAlertColor,
    showTicketChangeAlert,
    showModal,
    setShowModal,
}: TicketDetailsProps) => {
    const dispatch = useDispatch()

    const [
        responseNotification,
        setResponseNotification,
    ] = useState<ResponseNotification | null>(null)

    const providerId = useSelector(selectCurrentProviderId)
    const provider = useSelector(selectCurrentProvider)

    const [
        updateTicketStatus,
        { isSuccess: isUpdateStatusSuccess },
    ] = useUpdateTicketStatusMutation()
    const [
        updateTicketPlannedTimestamp,
        { isSuccess: isUpdatePlannedTimestampSuccess },
    ] = useUpdateTicketPlannedTimestampMutation()

    const { exportPdfTickets } = useExportTickets()

    const {
        control,
        register,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm<UpdateTicketInput>()

    const onSubmit = async (data: UpdateTicketInput) => {
        if (providerId) {
            try {
                if (data.newStatus && data.newStatus !== ticket.status) {
                    await updateTicketStatus({
                        providerId,
                        ticketId: ticket.id,
                        body: { newStatus: data.newStatus },
                    })
                }
                if (data.newPlannedDate) {
                    const newPlannedTimestamp = data.newPlannedDate.getTime()
                    if (
                        newPlannedTimestamp !== ticket.plannedExecutionTimestamp
                    ) {
                        updateTicketPlannedTimestamp({
                            providerId,
                            ticketId: ticket.id,
                            body: { newPlannedTimestamp },
                        })
                    }
                }

                if (isUpdateStatusSuccess || isUpdatePlannedTimestampSuccess) {
                    setResponseNotification({
                        status: 200,
                        message: 'Ticket wurde geändert.',
                    })
                }
            } catch (error) {
                const responseError = (error as ResponseError).data.error

                setResponseNotification(responseError)
            }
        } else {
            dispatch(cleanAuthState())
        }
    }

    const handleClose = () => {
        setShowModal(false)
        setResponseNotification(null)
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton onClick={handleClose}>
                    <div className='ds-icon-modal ms-auto flex-shrink-1'>
                        <div className='ds-icon-modal-center'>
                            <div className='ds-card-icon d-flex align-items-center justify-content-center'>
                                <i>
                                    <FontAwesomeIcon icon={faBroom} />
                                </i>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {responseNotification && (
                        <Row className='justify-content-center my-2'>
                            <Col>
                                <ResponseAlert
                                    status={responseNotification.status}
                                    message={responseNotification.message}
                                />
                            </Col>
                        </Row>
                    )}
                    {showTicketChangeAlert && (
                        <Card className='ds-card ds-card-alert'>
                            <Stack direction='horizontal'>
                                <div className='ms-auto flex-shrink-1'>
                                    <i className='ds-ticket-alert'>
                                        <FontAwesomeIcon
                                            icon={faTriangleExclamation}
                                            size='xl'
                                        />
                                    </i>
                                </div>
                                <div className='ps-3 w-100'>
                                    <Stack direction='horizontal' gap={1}>
                                        <div className='fw-bold mt-1'>
                                            Achtung es ist eine Ticketänderung
                                            aufgetreten. Die geplante
                                            Reinigungszeit liegt außerhalb des
                                            möglichen Reinigungszeitraums. Bitte
                                            ändern Sie die geplante
                                            Reinigungszeit.
                                        </div>
                                    </Stack>
                                </div>
                            </Stack>
                        </Card>
                    )}
                    <Stack direction='vertical' gap={4}>
                        <Stack direction='vertical' className='w-100'>
                            <small className='text-color-n3 mb-1'>
                                {ticket.shortId}{' '}
                                <i className={dueAlertColor}>
                                    <FontAwesomeIcon icon={faClock} />
                                </i>
                                &nbsp;
                                {formatExecutionDay(
                                    new Date(ticket.plannedExecutionTimestamp)
                                )}
                                {isExecutionSoon(
                                    new Date(ticket.plannedExecutionTimestamp)
                                ) === EXECUTION_DAY.TODAY &&
                                    isSameDay(
                                        new Date(
                                            ticket.plannedExecutionTimestamp
                                        ),
                                        new Date(
                                            ticket.latestExecutionTimestamp
                                        )
                                    ) && (
                                        <Fragment>
                                            &nbsp;von&nbsp;
                                            {plannedExecutionDate.toLocaleTimeString(
                                                'de-DE',
                                                {
                                                    timeZone: 'Europe/Berlin',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                }
                                            )}
                                            &nbsp;Uhr
                                        </Fragment>
                                    )}
                                {isExecutionSoon(
                                    new Date(ticket.plannedExecutionTimestamp)
                                ) === EXECUTION_DAY.TODAY &&
                                    !isSameDay(
                                        new Date(
                                            ticket.plannedExecutionTimestamp
                                        ),
                                        new Date(
                                            ticket.latestExecutionTimestamp
                                        )
                                    ) && (
                                        <Fragment>
                                            &nbsp;um&nbsp;
                                            {plannedExecutionDate.toLocaleTimeString(
                                                'de-DE',
                                                {
                                                    timeZone: 'Europe/Berlin',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                }
                                            )}
                                            &nbsp;Uhr
                                        </Fragment>
                                    )}
                                {isSameDay(
                                    new Date(ticket.plannedExecutionTimestamp),
                                    new Date(ticket.latestExecutionTimestamp)
                                ) && (
                                    <Fragment>
                                        &nbsp;bis&nbsp;
                                        {latestExecutionDate.toLocaleTimeString(
                                            'de-DE',
                                            {
                                                timeZone: 'Europe/Berlin',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            }
                                        )}
                                        &nbsp;Uhr
                                    </Fragment>
                                )}
                            </small>
                            <h4 className='text-break fw-bold'>
                                {TICKET_KIND_MAP[ticket.kind]}
                            </h4>
                            <div className='text-color-n3 ds-card-room-name'>
                                {ticket.bsRoomName}
                                <br />
                                (Wohneinheit-Id: {ticket.bsRoomId})
                            </div>
                        </Stack>
                        <Stack direction='horizontal' gap={2}>
                            <span className='fw-bold'>Status:</span>
                            <Form.Select
                                className='w-auto'
                                defaultValue={getMappedTicketStatus(
                                    ticket.status
                                )}
                                {...register('newStatus')}
                            >
                                {Object.values(UPDATE_STATUS)
                                    .filter(status => isNaN(Number(status)))
                                    .map((_, index) => (
                                        <option
                                            key={`update-status-dropdown-${index}`}
                                            value={index}
                                        >
                                            {TICKET_STATUS_MAP[index]}
                                        </option>
                                    ))}
                            </Form.Select>
                        </Stack>

                        <Stack direction='vertical' gap={1}>
                            <h5 className='fw-bold'>{ticket.bsObjectName}</h5>
                            <Stack direction='vertical'>
                                <span>
                                    {ticket.address?.street}{' '}
                                    {ticket.address?.additionalInfo}
                                </span>
                                <span>
                                    {ticket.address?.zipcode}{' '}
                                    {ticket.address?.city}
                                </span>
                            </Stack>
                        </Stack>
                        <Stack direction='horizontal' gap={1}>
                            <Button
                                href={`https://maps.google.de/?q=${ticket.address?.street} ${ticket.address?.additionalInfo} ${ticket.address?.zipcode} ${ticket.address?.city}`}
                                variant='secondary'
                                target='_blank'
                            >
                                <i className='me-1'>
                                    <FontAwesomeIcon icon={faLocationDot} />
                                </i>{' '}
                                Lage anzeigen
                            </Button>
                            <Button
                                variant='secondary'
                                onClick={() =>
                                    exportPdfTickets(provider, [ticket])
                                }
                            >
                                <i className='me-1'>
                                    <FontAwesomeIcon icon={faDownload} />
                                </i>{' '}
                                Ticket exportieren
                            </Button>
                        </Stack>
                        <Stack
                            direction='vertical'
                            gap={1}
                            className='ds-bg-section'
                        >
                            <h5 className='fw-bold'>
                                Informationen aus der Buchung
                            </h5>
                            <Stack direction='vertical'>
                                <FormGroup as={Row}>
                                    <Form.Label column sm='4'>
                                        Anreise:
                                    </Form.Label>
                                    <Col sm='8'>
                                        <Form.Control
                                            plaintext
                                            readOnly
                                            defaultValue={
                                                ticket.arrival
                                                    ? formatDateToLocaleTimeString(
                                                          new Date(
                                                              ticket.arrival
                                                          )
                                                      )
                                                    : 'keine Daten'
                                            }
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column sm='4'>
                                        Abreise:
                                    </Form.Label>
                                    <Col sm='8'>
                                        <Form.Control
                                            plaintext
                                            readOnly
                                            defaultValue={
                                                ticket.departure
                                                    ? formatDateToLocaleTimeString(
                                                          new Date(
                                                              ticket.departure
                                                          )
                                                      )
                                                    : 'keine Daten'
                                            }
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column sm='4'>
                                        Anzahl Erwachsene:
                                    </Form.Label>
                                    <Col sm='8'>
                                        <Form.Control
                                            plaintext
                                            readOnly
                                            defaultValue={`${
                                                ticket.adults
                                                    ? ticket.adults
                                                    : 'keine'
                                            }`}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column sm='4'>
                                        Anzahl Kinder:
                                    </Form.Label>
                                    <Col sm='8'>
                                        <div
                                            style={{
                                                display: 'inline',
                                                float: 'left',
                                                marginTop: '10px',
                                            }}
                                        >
                                            <ul
                                                style={{
                                                    paddingLeft: '15px',
                                                }}
                                            >
                                                {ticket.childs &&
                                                ticket.childs.length > 0 ? (
                                                    ticket.childs.map(
                                                        (child, index) => (
                                                            <li
                                                                key={`childs-${index}`}
                                                            >
                                                                {child.age}{' '}
                                                                Jahre (
                                                                {child.count}
                                                                x)
                                                            </li>
                                                        )
                                                    )
                                                ) : (
                                                    <li
                                                        key={'childsEmpty'}
                                                        style={{
                                                            listStyleType:
                                                                'none',
                                                            marginLeft: '-15px',
                                                        }}
                                                    >
                                                        keine
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column sm='4'>
                                        Bemerkungen:
                                    </Form.Label>
                                    <Col sm='8'>
                                        <Form.Control
                                            style={{
                                                marginTop: '10px',
                                                padding: '0.5em',
                                                border: 'solid darkgrey',
                                            }}
                                            as='textarea'
                                            rows={4}
                                            plaintext
                                            readOnly
                                            defaultValue={
                                                ticket.notes
                                                    ? ticket.notes
                                                    : 'keine'
                                            }
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column sm='4'>
                                        Zusatzleistungen:
                                    </Form.Label>
                                    <Col sm='8'>
                                        <div
                                            style={{
                                                display: 'inline',
                                                float: 'left',
                                                marginTop: '10px',
                                            }}
                                        >
                                            <ul
                                                style={{
                                                    paddingLeft: '15px',
                                                }}
                                            >
                                                {ticket.additionalServices &&
                                                ticket.additionalServices
                                                    .length > 0 ? (
                                                    ticket.additionalServices.map(
                                                        (item, index) => (
                                                            <li
                                                                key={`addServices-${index}`}
                                                            >
                                                                {item.as_name} (
                                                                {item.as_count}
                                                                x)
                                                            </li>
                                                        )
                                                    )
                                                ) : (
                                                    <li
                                                        key={'addServicesEmpty'}
                                                        style={{
                                                            listStyleType:
                                                                'none',
                                                            marginLeft: '-15px',
                                                        }}
                                                    >
                                                        keine
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </Col>
                                </FormGroup>
                            </Stack>
                        </Stack>
                        <Stack
                            direction='vertical'
                            gap={1}
                            className='ds-bg-section'
                        >
                            <h5 className='fw-bold'>
                                Informationen aus der nächsten Buchung
                            </h5>
                            <Stack direction='vertical'>
                                {ticket.nextAdults ? (
                                    <>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm='4'>
                                                Anreise:
                                            </Form.Label>
                                            <Col sm='8'>
                                                <Form.Control
                                                    plaintext
                                                    readOnly
                                                    defaultValue={
                                                        ticket.nextArrival
                                                            ? formatDateToLocaleTimeString(
                                                                  new Date(
                                                                      ticket.nextArrival
                                                                  )
                                                              )
                                                            : 'keine Daten'
                                                    }
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm='4'>
                                                Abreise:
                                            </Form.Label>
                                            <Col sm='8'>
                                                <Form.Control
                                                    plaintext
                                                    readOnly
                                                    defaultValue={
                                                        ticket.nextDeparture
                                                            ? formatDateToLocaleTimeString(
                                                                  new Date(
                                                                      ticket.nextDeparture
                                                                  )
                                                              )
                                                            : 'keine Daten'
                                                    }
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm='4'>
                                                Anzahl Erwachsene:
                                            </Form.Label>
                                            <Col sm='8'>
                                                <Form.Control
                                                    plaintext
                                                    readOnly
                                                    defaultValue={`${
                                                        ticket.nextAdults
                                                            ? ticket.nextAdults
                                                            : 'keine'
                                                    }`}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm='4'>
                                                Anzahl Kinder:
                                            </Form.Label>
                                            <Col sm='8'>
                                                <div
                                                    style={{
                                                        display: 'inline',
                                                        float: 'left',
                                                        marginTop: '10px',
                                                    }}
                                                >
                                                    <ul
                                                        style={{
                                                            paddingLeft: '15px',
                                                        }}
                                                    >
                                                        {ticket.nextChilds &&
                                                        ticket.nextChilds
                                                            .length > 0 ? (
                                                            ticket.nextChilds.map(
                                                                (
                                                                    child,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={`nextChilds-${index}`}
                                                                    >
                                                                        {
                                                                            child.age
                                                                        }{' '}
                                                                        Jahre (
                                                                        {
                                                                            child.count
                                                                        }
                                                                        x)
                                                                    </li>
                                                                )
                                                            )
                                                        ) : (
                                                            <li
                                                                key={
                                                                    'nextChildsEmpty'
                                                                }
                                                                style={{
                                                                    listStyleType:
                                                                        'none',
                                                                    marginLeft:
                                                                        '-15px',
                                                                }}
                                                            >
                                                                keine
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm='4'>
                                                Bemerkungen:
                                            </Form.Label>
                                            <Col sm='8'>
                                                <Form.Control
                                                    style={{
                                                        marginTop: '10px',
                                                        padding: '0.5em',
                                                        border:
                                                            'solid darkgrey',
                                                    }}
                                                    as='textarea'
                                                    rows={4}
                                                    plaintext
                                                    readOnly
                                                    defaultValue={
                                                        ticket.nextNotes
                                                            ? ticket.nextNotes
                                                            : 'keine'
                                                    }
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm='4'>
                                                Zusatzleistungen:
                                            </Form.Label>
                                            <Col sm='8'>
                                                <div
                                                    style={{
                                                        display: 'inline',
                                                        float: 'left',
                                                        marginTop: '10px',
                                                    }}
                                                >
                                                    <ul
                                                        style={{
                                                            paddingLeft: '15px',
                                                        }}
                                                    >
                                                        {ticket.nextAdditionalServices &&
                                                        ticket
                                                            .nextAdditionalServices
                                                            .length > 0 ? (
                                                            ticket.nextAdditionalServices.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={`nextAddServices-${index}`}
                                                                    >
                                                                        {
                                                                            item.as_name
                                                                        }{' '}
                                                                        (
                                                                        {
                                                                            item.as_count
                                                                        }
                                                                        x)
                                                                    </li>
                                                                )
                                                            )
                                                        ) : (
                                                            <li
                                                                key={
                                                                    'nextAddServicesEmpty'
                                                                }
                                                                style={{
                                                                    listStyleType:
                                                                        'none',
                                                                    marginLeft:
                                                                        '-15px',
                                                                }}
                                                            >
                                                                keine
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </>
                                ) : (
                                    'keine weitere Buchung vorhanden'
                                )}
                            </Stack>
                        </Stack>
                        <Stack
                            direction='vertical'
                            gap={1}
                            className='ds-bg-section'
                        >
                            <h5 className='fw-bold'>
                                Tag der Ausführung festlegen
                            </h5>
                            <Controller
                                name='newPlannedDate'
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        className='ds-date-picker-inline'
                                        selected={
                                            new Date(
                                                ticket.plannedExecutionTimestamp
                                            )
                                        }
                                        onChange={(date: Date) =>
                                            field.onChange(date)
                                        }
                                        filterDate={date => {
                                            return (
                                                date.getTime() >=
                                                    ticket.earliestExecutionTimestamp -
                                                        24 * 60 * 60 * 1000 &&
                                                date.getTime() <=
                                                    ticket.latestExecutionTimestamp
                                            )
                                        }}
                                        inline
                                    />
                                )}
                            />
                        </Stack>
                        {ticket.content && (
                            <Stack direction='vertical' gap={1}>
                                <h5 className='fw-bold'>Aufgabe</h5>
                                <Stack direction='vertical'>
                                    <Card className='ds-card'>
                                        <span className='fw-bold mb-1'>
                                            {ticket.kind ===
                                            TICKET_KIND.CLEANING
                                                ? 'Reinigung'
                                                : 'Wartung'}
                                            sanweisung:
                                        </span>
                                        {ticket.content
                                            .split('\n')
                                            .map(function (item, idx) {
                                                return (
                                                    <span key={idx}>
                                                        {item}
                                                        <br />
                                                    </span>
                                                )
                                            })}
                                    </Card>
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        id='ticketSubmitButton'
                        type='submit'
                        className='w-100'
                        disabled={isTicketInProgress(ticket)}
                    >
                        <Stack
                            direction='horizontal'
                            gap={2}
                            className='justify-content-center'
                        >
                            {isSubmitting && (
                                <Spinner animation='border' size='sm' />
                            )}
                            {isTicketInProgress(ticket)
                                ? 'Ticket im Update-Prozess (ca. 2-3 Min.)'
                                : 'Speichern'}
                        </Stack>
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default TicketDetails
